export function getCookie(name: string) {
  if (typeof document === 'undefined') {
    return null;
  }

  if (!document.cookie) {
    return null;
  }
  const cookieDict = document.cookie
    .split(';')
    .map((x) => x.split('='))
    .reduce((accum, current) => {
      accum[current[0].trim()] = current[1];
      return accum;
    }, Object());

  return cookieDict[name];
}

export function setCookie(name: string, value: string) {
  const date = new Date();
  date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/;`;
}

export function deleteCookie(name: string) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}